"use client";

import { CreditCards } from "@/components/CreditCards";
import Image from "@/components/Image";
import { MINISTORE } from "@/lib/const";
import type { Payload } from "@local/payload-client/src/types";
import { BunnyLoader } from "@local/utils/src/bunnyLoader";
import { classNames } from "@local/utils/src/classNames";
import { ContentBlockServer } from "./ContentBlock";
import type { RatingScores } from "./Reviews/Stars";
import { StarsWithReviews } from "./Reviews/StarsWithReviews";
import ButtonServer, { ButtonIntent } from "./ui/button";
import MuxPlayer from "@mux/mux-player-react";
import HydrationContainer from "./HydrationContainer";
interface Props {
  block?: Payload.PromoHeadlineVideoWithStarsBlock;
}
export const PromoHeadlineVideoWithStarsServer = ({
  block
}: Props) => {
  if (block) {
    return <div className="mb-4">
        <ContentBlockServer className="relative flex justify-center px-6 py-10 mb-4 text-center lg:py-20">
          <div className={classNames("absolute top-0 z-10 w-full h-full bg-opacity-50 ", block.brightBackground ? "" : "bg-black")} />
          {block.videoId ? <HydrationContainer>
              <MuxPlayer autoPlay="muted" defaultHiddenCaptions loop metadataVideoTitle={block.headline} muted nohotkeys playbackId={block.videoId} poster={block.fallbackImage.url} streamType="on-demand" maxResolution="720p" />
              <style>
                {`
            mux-player {
              --controls: none;
              --media-object-fit: cover;
              position: absolute;
              inset: 0;
              z-index: 5;
            }
            mux-player::part(gesture-layer) {
              display: none;
            }
          `}
              </style>
            </HydrationContainer> : null}
          <Image alt={block.headline || ""} height={block.fallbackImage.height} src={block.fallbackImage.url} width={block.fallbackImage.width} className="absolute top-0 z-0 object-cover w-full h-full" loader={process.env.NEXT_PUBLIC_BUNNY_URL ? BunnyLoader : undefined} />
          <div className={classNames("z-10 flex flex-col lg:my-10 w-full lg:w-5/12 lg:mx-auto", block.alignLeft ? "items-start" : "items-center", block.brightBackground ? "text-gray-900" : "text-white")}>
            {block.headline && <h2 className={classNames("mb-4 font-bold", block.subtitle ? "text-3xl" : "text-5xl",
          // block.alignLeft ? "justify-start" : "justify-center"
          true ? "w-fit" : "w-full md:w-4/5")} dangerouslySetInnerHTML={{
            __html: block.headline
          }} />}
            <p className="mb-5 text-lg font-normal leading-[1.333rem]">
              {block.subtitle}
            </p>
            {block.ctaContent && <div className="mx-auto">
                <ButtonServer content={block.ctaContent} url={block.ctaUrl} intent={ButtonIntent.CTA} className="mb-4" />
              </div>}
            {block.showReviewStars && <div className="flex justify-center mx-auto mb-5 md:mx-0 max-w-fit md:max-w-none">
                <StarsWithReviews rating={parseFloat(block.starsRating as string) as RatingScores} reviews={block.reviewsAmount} overrideColor={MINISTORE === "baerskintactical" ? "#1BB57C" : "#fbbf24"} dark />
              </div>}
            {block.showCreditCards && <div className="max-w-[500px] mx-auto md:mx-0 bg-white rounded-full px-2 py-1">
                <CreditCards fillHeight />
              </div>}
          </div>
        </ContentBlockServer>
      </div>;
  } else {
    //TODO: add loading state
    return <>Loading...</>;
  }
};
export default PromoHeadlineVideoWithStarsServer;